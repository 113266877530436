import React from "react"
import Layout from "../components/layout";
import { Container } from "@material-ui/core";
import { constants } from "../constants";
import { PageHeader } from "../components/PageHeader";
import styled from "@emotion/styled";

interface WhereListItemProps {
  url: string;
}

const WhereList = styled.ul`

  font-size: 1.9rem;
  @media (max-width: 750px) {
    font-size: 1.6rem;
  }
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
  
  list-style: none;
  padding: 0;
  text-align: center;

  li {
    padding: 16px 0;
  
    a {
      color: white;
    }

  }

`;

const WhereListItem: React.FC<WhereListItemProps> = props => {
  return (
    <li>
      <a href={props.url} target={"_blank"}>
        {props.children}
      </a>
    </li>
  );
};

const WherePage = () => {

  return (
    <Layout>
      <Container maxWidth="md">

        <PageHeader>
          Where
        </PageHeader>

        <WhereList>

          <WhereListItem url={`https://twitter.com/${constants.twitter}`}>
            {/*<TwitterIcon htmlColor={"white"}/> */}
            Twitter: @{constants.twitter}
          </WhereListItem>

          <WhereListItem url={`https://www.instagram.com/${constants.instagram}`}>
            {/*<InstagramIcon htmlColor={"white"}/>*/}
            Instagram: @{constants.instagram}
          </WhereListItem>

          <WhereListItem url={`https://det.social/@JackSleepwalker`}>
            Mastodon: @JackSleepwalker@det.social
          </WhereListItem>

          <WhereListItem url={"https://endzeitromantick.wordpress.com/"}>
            Roman: Endzeitromantick
          </WhereListItem>

          <WhereListItem url={"https://zwischensehquenzen.wordpress.com/"}>
            Gedichte: ZwischenSehQuenzen
          </WhereListItem>

          <WhereListItem url={"http://www.vollfuck.de/"}>
            Blog: vollfuck.de
          </WhereListItem>

          <WhereListItem url={"https://www.aphorismen.de/suche?autor_quelle=jack+sleepwalker"}>
            Ich @ aphorismen.de
          </WhereListItem>

          <WhereListItem url={`https://www.etsy.com/de/shop/${constants.etsy}`}>
            Etsy: @{constants.etsy}
          </WhereListItem>

          <WhereListItem url={`https://www.ebay.de/usr/${constants.ebay}`}>
            eBay: @{constants.ebay}
          </WhereListItem>

          <WhereListItem url={`https://queerwelten.de/qw2-das-cover-artwork/`}>
            ich @ Queer*Welten
          </WhereListItem>

          <WhereListItem url={`https://duisburg.gay-web.info/city-guide/jack-sleepwarker`}>
            ich @ Gayweb Info Künstler
          </WhereListItem>

          <WhereListItem url={`/downloads/Collage-Wir-Sind-Anders-2019.pdf`}>
            Collage bei Ausstellung 2019 "Wir sind anders"
          </WhereListItem>

          <WhereListItem url={`/downloads/Einladung-Queerschlag-2020.pdf`}>
            Eigene Ausstellung 2020 "Queerschlag"
          </WhereListItem>

          <WhereListItem url={`https://www.regenbogenportal.de/fuer-fachkraefte/veranstaltungsarchiv/detail/queer-story-telling`}>
            2022 Präsentation meiner Collage "Das Leben ist mehr..."
          </WhereListItem>

          <WhereListItem url="http://dugay.de/">
            Eigene Ausstellung 2023 "Walk of Pride - don*t hide!" bei der Duisburger CSD-Demo 
          </WhereListItem>

        </WhereList>

      </Container>
    </Layout>
  );

};

export default WherePage
